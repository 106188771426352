import { Plano } from './plano.model';
import { PerfilLicenca } from './perfil-licenca.model';

export class Licenca extends Plano {

  prazo = 1;
  periodo = 'HORA';
  status = 'LIBERADO';
  ativo = true;
  perfis: PerfilLicenca[];

}
