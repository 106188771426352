import { Injectable } from '@angular/core';
import { MoneyHttp } from '../seguranca/money-http';
import { environment } from '../../../environments/environment';
import { Search } from '../../shared/models/search.model';
import { Perfil } from '../../shared/models/perfil.model';

@Injectable()
export class PerfilService {

  perfilUrl: string;

  permissaoUrl: string;

  constructor(private http: MoneyHttp) {
    this.perfilUrl = `${environment.apiUrl}/perfis`;
    this.permissaoUrl = `${environment.apiUrl}/permissoes`;
  }

  search(search: Search): Promise<any> {
    search.sortField = !search.sortField ? 'nome' : search.sortField;
    return this.http.post<any>(`${this.perfilUrl}/_search`, search)
      .toPromise()
      .then(response =>  {
        const perfis = response.content;
        return perfis;
      });
  }

  searchPermissoes(search: Search): Promise<any> {
    return this.http.post<any>(`${this.permissaoUrl}/_search`, search)
      .toPromise()
      .then(response => {
        const permissoes = response.content;
        return permissoes;
      });
  }

  findByid(id: number): Promise<Perfil> {
    return this.http.get<Perfil>(`${this.perfilUrl}/${id}`)
    .toPromise();
  }

  salvar(perfil: Perfil): Promise<Perfil> {
    return this.http.post<Perfil>(this.perfilUrl, perfil)
      .toPromise()
      .then(response => response);
  }

  atualizar(perfil: Perfil): Promise<Perfil> {
    return this.http.put<Perfil>(`${this.perfilUrl}/${perfil.id}`, perfil)
      .toPromise()
      .then(response => response);
  }

  excluir(id: number): Promise<any> {
    return this.http.delete<any>(`${this.perfilUrl}/${id}`)
      .toPromise()
      .then(response => response);
  }

}
